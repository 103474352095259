<template>
  <div class="no-result">
    <section class="banner no-result__banner">
      <div class="container">
        <h2 class="t-white" v-text="$t('Search results')" />
      </div>
    </section>
    <div class="no-result__content">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="no-result__image">
              <img src="@/assets/img/no_results.svg" alt="Books not found" />
            </div>
          </div>
          <div class="col-12 col-md-8">
            <section class="no-result__section">
              <h2>
                {{ $t('sorry_title') }}
              </h2>
              <p>
                {{ $t('sorry_message') }}
              </p>
            </section>
            <hr />
            <section class="no-result__section">
              <h2>{{ $t('title') }}</h2>
              <p>
                {{ $t('message') }}
              </p>
            </section>
          </div>
        </div>
      </div>

      <search-advance />
    </div>
  </div>
</template>

<script>
import SearchAdvance from '../../home/searchAdvance/SearchAdvance';

export default {
  name: 'NoResult',

  components: {
    SearchAdvance,
  },
};
</script>

<i18n src="./translations.json"></i18n>

<style lang="scss">
@import './no-result.scss';
</style>
